<template>
  <div class="container-fluid py-4">
    <div class="d-sm-flex justify-content-between">
      <div class="d-flex">
        <soft-button
          class="btn-icon ms-2 export"
          size
          color="dark"
          variant="outline"
          data-type="csv"
          @click="exportDataTable"
        >
          <span class="btn-inner--icon">
            <i class="ni ni-archive-2"></i>
          </span>
          <span class="btn-inner--text">Export CSV</span>
        </soft-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4 p-3 pt-0">
          <div class="table-responsive">
            <div class="search-bar">
              <soft-input
                style="width: 100%"
                @input="searchForOrder"
                placeholder="Search By Order #, PO#"
              >
              </soft-input>
              <div
                v-click-outside="() => (toggleColorDropdown = false)"
                class="color-key-container"
              >
                <div class="">
                  <!-- <div class="" id="headingOne">
                      <h5 class="mb-0">
                        <a
                          data-bs-toggle="collapse"
                          href="#color-key-dropdown"
                          aria-controls="color-key-dropdown"
                          aria-expanded="false"
                          class="btn btn-link"
                          v-bind="$attrs"
                          type="button"
                          @click="toggleColorDropdown = !toggleColorDropdown"
                        >
                          Color Key
                        </a>
                      </h5>
                    </div> -->

                  <!-- <div
                      id="color-key-dropdown"
                      class="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="color-key-dropdown">
                        <div
                          class="color-key-list"
                          v-for="(color, status) in statusColors"
                          :key="status"
                        >
                          <div
                            @click="filterByStatus(status)"
                            :style="{
                              backgroundColor: color,
                              cursor: 'pointer',
                              color: status.includes('Delayed')
                                ? 'white'
                                : 'black',
                            }"
                            class="color-status"
                          >
                            {{ status }}
                          </div>
                        </div>
                      </div>
                    </div> -->
                </div>
              </div>
            </div>
            <div class="light">
              <div class="filters">
                <div class="filter customer">
                  <p class="filter-label">Status</p>
                  <v-select
                    :deselectFromDropdown="true"
                    v-model="filterBy.status"
                    :options="[
                      'Order Created',
                      'In Process',
                      'Shipped To DIS',
                      'Delayed',
                    ]"
                    :closeOnSelect="true"
                  >
                  </v-select>
                </div>

                <!-- <div class="filter">
                    <p>Start From:</p>
                    <md-datepicker
                      class="datepicker"
                      @input="validateDateRange('startDate')"
                      v-model="filterBy.startDate"
                    />
                  </div>
                  <div class="filter">
                    <p>End To:</p>
                    <md-datepicker
                      class="datepicker"
                      @input="validateDateRange('endDate')"
                      v-model="filterBy.endDate"
                    />
                  </div> -->

                <div class="filter filter-btn">
                  <soft-button
                    type="button"
                    color="primary"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="handleFilter"
                  >
                    Filter
                  </soft-button>
                  <soft-button
                    type="button"
                    color="warning"
                    variant="gradient"
                    class="ms-auto mb-0 js-btn-next"
                    @click="clearFilters"
                  >
                    Clear
                  </soft-button>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table id="order-list" class="table table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>Order Created On</th>
                    <th>Order #</th>
                    <th>PO #</th>
                    <th>SKU</th>
                    <th>Origin</th>
                    <th>Client</th>
                    <th>Sales Rep</th>
                    <th>Store ID</th>
                    <th>Item Type</th>
                    <th>Quantity</th>
                    <th>Price Breakdown</th>
                    <!-- <th>Status</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="order in queriedData"
                    :key="order.id"
                    class="table-row"
                    data-bs-toggle="modal"
                    data-bs-target="#Modal"
                    @click="viewOrderDetails(order)"
                    :class="{ 'table-active': selectedOrder.id == order.id }"
                  >
                    <td class="font-weight-bold">
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ format_date_time(order.createdAt) }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.orderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.purchaseOrderNumber }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.sku }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.origin }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ `${order.client.company}` }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{
                            `${order.salesRep.firstName} ${order.salesRep.lastName}`
                          }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.consumer.storeId }}
                        </p>
                      </div>
                    </td>
                    <td>
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.jewelryType }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <div>
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.quantity }}
                        </p>
                      </div>
                    </td>

                    <td>
                      <table>
                        <thead>
                          <tr>
                            <th>Trezza's Price</th>
                            <th>Luna's Total Cost</th>
                            <th>Sales Rep Total Cost</th>
                            <th>Store Paid</th>
                            <th>Order Total Cost</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{
                                format_price(
                                  calculateCurrentUnitPrice(
                                    order.priceBreakdown.storeTotalCost,
                                    order.origin
                                  )
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  calculateLunaCost(
                                    calculateCurrentUnitPrice(
                                      order.priceBreakdown.storeTotalCost,
                                      order.origin
                                    )
                                  )
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  calculateSalesRepTotal(
                                    calculateCurrentUnitPrice(
                                      order.priceBreakdown.storeTotalCost,
                                      order.origin
                                    ),
                                    order.origin
                                  )
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  order.priceBreakdown.storeTotalCost
                                )
                              }}
                            </td>
                            <td>
                              {{
                                format_price(
                                  order.priceBreakdown.storeTotalCost * 2
                                )
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table>
                        <thead>
                          <tr>
                            <th>Sales Rep Commission</th>
                            <th>Luna Commission</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {{
                                format_price(
                                  order.priceBreakdown.storeTotalCost
                                )
                              }}
                              -
                              {{
                                format_price(
                                  calculateSalesRepTotal(
                                    calculateCurrentUnitPrice(
                                      order.priceBreakdown.storeTotalCost,
                                      order.origin
                                    ),
                                    order.origin
                                  )
                                )
                              }}

                              =

                              <span style="color: red">
                                {{
                                  format_price(
                                    order.priceBreakdown.storeTotalCost -
                                      calculateSalesRepTotal(
                                        calculateCurrentUnitPrice(
                                          order.priceBreakdown.storeTotalCost,
                                          order.origin
                                        ),
                                        order.origin
                                      )
                                  )
                                }}
                              </span>
                            </td>
                            <td>
                              <span style="color: green">
                                {{
                                  format_price(
                                    calculateLunaCommission(
                                      calculateSalesRepTotal(
                                        calculateCurrentUnitPrice(
                                          order.priceBreakdown.storeTotalCost,
                                          order.origin
                                        ),
                                        order.origin
                                      ),
                                      calculateLunaCost(
                                        calculateCurrentUnitPrice(
                                          order.priceBreakdown.storeTotalCost,
                                          order.origin
                                        )
                                      )
                                    )
                                  )
                                }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <!-- <td class="text-xs font-weight-bold">
                      <div class="align-items-center">
                        <p class="text-xs font-weight-bold ms-2 mb-0">
                          {{ order.status }}
                        </p>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <nav aria-label="Page navigation example">
              <div class="pagination-wrapper">
                <div class="page-dropdown">
                  <label for="orders">Per page: &nbsp; </label>
                  <select v-model="pagination.perPage" name="orders">
                    <option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </div>

                <pagination
                  :value="pagination.currentPage"
                  class="pagination-no-border pagination-success"
                  :per-page="pagination.perPage"
                  :total="total"
                  :from="from"
                  :to="to"
                  @input="handlePagination"
                >
                </pagination>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    ref="contentToExport"
    :styleFormat="{ width: '1000px', marginLeft: '-10.75rem' }"
    @close="(showModal = false), (selectedOrder = {})"
  >
    <template v-slot:title>
      <h2>{{ selectedOrder.orderNumber }} Details</h2>
    </template>
    <template v-slot:body>
      <div class="order-details-wrapper">
        <OrderDetails @handle-input="handleInput" :order="selectedOrder" />
      </div>
    </template>
  </modal>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftInput from "@/components/SoftInput.vue";
import Modal from "@/components/Modal.vue";
import OrderDetails from "./OrderDetails.vue";
import Pagination from "@/components/Pagination.vue";
import ExcelJS from "exceljs";

import {
  setDoc,
  collection,
  doc,
  db,
  orderBy,
  query,
  updateDoc,
  onSnapshot,
  where,
  endAt,
  limit,
  getDoc,
} from "@/config/firebaseInit.js";

export default {
  name: "OrderList",
  components: {
    SoftButton,
    SoftAvatar,
    SoftCheckbox,
    SoftInput,
    Modal,
    OrderDetails,
    Pagination,
  },
  data() {
    return {
      toggleColorDropdown: false,
      searchedData: [],
      totalLunaCommissionTally: 0,
      filterBy: {
        status: "",
        customers: [],
        startDate: "",
        endDate: "",
      },
      statusColors: {
        "Order Created": "#58E287",
        "In Process": "rgb(255, 239, 0)",
        "Shipped To DIS": "rgb(0, 243, 255)",
        "QA Staging": "#9cb0ff",
        "Shipped To Consumer": "rgb(231, 66, 236)",
        Delayed: "black",
      },
      metals: {
        wg: "White Gold",
        yg: "Yellow Gold",
        rg: "Rose Gold",
        plt: "Platinum",
      },
      shapeType: {
        earth: "Natural Stone",
        lab: "Lab Made",
      },
      tableData: [],
      orders: [],
      showModal: false,
      showExportOptions: false,
      selectedOrder: {},
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 250, 500, 1000],
        total: 0,
      },
    };
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data.
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData.slice(this.from, this.to);
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.length;
    },
  },
  watch: {
    toggleColorDropdown(val) {
      let myCollapse = document.getElementById("color-key-dropdown");
      if (!val) {
        setTimeout(() => {
          myCollapse.classList.remove("show");
          let bsCollapse = new bootstrap.Collapse(myCollapse, {
            toggle: false,
          });
        }, 500);
      } else {
        let bsCollapse = new bootstrap.Collapse(myCollapse, {
          toggle: true,
        });
      }
    },
  },
  created() {
    // this.getAllOrders();
    this.fetchData("start");
  },
  methods: {
    async exportDataTable() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet 1");

      const header = [
        [
          "Order Number",
          "PO Number",
          "Jewelry Details",
          "Engraving",
          "Birthstones",
          "Status",
        ],
      ];

      const dataToExport = this.queriedData.map((order) => {
        return [
          order.orderNumber,
          order.purchaseOrderNumber,
          `Name: ${order.style}\nShape: ${
            this.shapeType[order.shapeType]
          }\nCarat: ${this.addCTTW(order.sku)}\nMetal: ${
            this.metals[order.metal]
          } \nSize: ${order.ringSize}`,
          `Message: ${order.engraving ? order.engraving.text : "None"}\nFont: ${
            order.engraving ? order.engraving.font : "None"
          }`,
          `${
            order.birthStones.length
              ? order.birthStones.map((s) => s.name).join("\n")
              : "None"
          } `,
          order.status,
        ];
      });

      const data = header.concat(dataToExport);
      // Add data to the worksheet
      data.forEach((row) => {
        const excelRow = worksheet.addRow(row);

        // Apply text wrap and center alignment
        excelRow.eachCell((cell) => {
          cell.alignment = {
            vertical: "justify",
            horizontal: "left",
            wrapText: true,
          };
        });
      });

      worksheet.columns.forEach((column) => {
        column.width = 45; // Adjust the width as needed
      });

      // Set response headers for download
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `Orders Spreadsheet.xlsx`;
      link.click();
    },
    async saveChanges() {
      const selectedOrder = this.selectedOrder;
      if (selectedOrder.status.includes("Shipped")) {
        selectedOrder.shippingDetails.shippedOn = Date.now();
      }

      if (!selectedOrder.proccessedBy)
        selectedOrder.proccessedBy = Object.assign({
          id: this.$store.state.authModule.admin.id,
          fullName: `${this.$store.state.authModule.admin.firstName} ${this.$store.state.authModule.admin.lastName}`,
        });

      await setDoc(doc(db, "orders", selectedOrder.id), selectedOrder).then(
        () => {
          this.$swal({
            icon: "success",
            title: "Successful Update",
            text: `Order # ${selectedOrder.id} has been updated`,
            type: "success",
          });
        }
      );
    },
    handleInput(e, key, subKey) {
      if (subKey) {
        this.selectedOrder[key][subKey] = e.target.value;
      } else {
        this.selectedOrder[key] = e.target.value;
      }
    },

    handlePagination(value) {
      this.pagination.currentPage = value;
    },
    viewOrderDetails(order) {
      this.selectedOrder = order;
      this.showModal = true;
    },

    addCTTW(sku) {
      const cctw = sku.split("-");
      return `${cctw[cctw.length - 1]} ct`;
    },

    calculateCurrentUnitPrice(currentPrice, origin) {
      if (origin === "Lab Grown") {
        return parseFloat(((currentPrice - 135) / 1.2 / 1.3 / 1.06).toFixed(2));
      } else {
        return parseFloat(
          ((currentPrice - 135) / 1.15 / 1.2 / 1.06).toFixed(2)
        );
      }
    },
    calculateLunaCost(unitPrice) {
      return unitPrice * 0.06 + unitPrice + 135;
    },
    calculateSalesRepTotal(unitPrice, origin) {
      if (origin === "Lab Grown") {
        return parseFloat(
          ((unitPrice * 0.06 + unitPrice) * 1.3 + 135).toFixed(2)
        );
      } else {
        return parseFloat(
          ((unitPrice * 0.06 + unitPrice) * 1.2 + 135).toFixed(2)
        );
      }
    },

    calculateLunaCommission(salesTotalCost, lunaTotalCost) {
      return salesTotalCost - lunaTotalCost;
    },
    //Fetch Order Methods:

    async getAllOrders() {
      const orderRef = collection(db, "orders");
      const orderQuery = query(
        orderRef,
        orderBy("purchaseOrderNumber", "desc")
      );

      const unsub = onSnapshot(
        orderQuery,
        (querySnapshot) => {
          this.orders = querySnapshot.docs.map((user) =>
            Object.assign({ id: user.id }, user.data())
          );
          this.tableData = this.orders;
        },
        (error) => {}
      );
    },

    resetTable() {
      this.pagination.currentPage = 1;
      this.searchedData = [];
      this.fetchData("start");
    },

    async fetchData(action) {
      if (this.searchedData.length) return false;
      if (action == "start") {
        try {
          const orderRef = collection(db, "orders");
          let orderQuery;
          orderQuery = query(
            orderRef,
            orderBy("createdAt", "desc"),
            endAt(0),
            limit(this.pagination.perPage)
          );

          const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
            this.orders = querySnapshot.docs.map((doc) =>
              Object.assign({ id: doc.id }, doc.data())
            );
            this.tableData = this.orders;
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (action == "next") {
        this.fetchNextData();
      } else if (action == "prev") {
        this.fetchPreviousData();
      }

      // else if (action == "per-page") {
      //   let firstDoc = this.tableData[0];
      //   firstDoc = await this.productsRef.doc(firstDoc.id).get();
      //   const querySnapshot = await this.productsRef
      //     .orderBy("createdAt", "desc") // Change to your sorting criteria
      //     .startAt(firstDoc)
      //     .limit(this.pagination.perPage)
      //     .get();

      //   this.tableData = querySnapshot.docs.map((doc) =>
      //     Object.assign({ id: doc.id }, doc.data())
      //   );
      // }
    },

    async fetchNextData() {
      const orderRef = collection(db, "orders");
      let lastDoc = this.tableData[this.tableData.length - 1];
      lastDoc = await getDoc(doc(orderRef, lastDoc.id));
      this.tableData = [];
      try {
        const orderRef = collection(db, "orders");
        let orderQuery;
        orderQuery = query(
          orderRef,
          orderBy("createdAt", "desc"),
          startAfter(lastDoc),
          limit(this.pagination.perPage)
        );

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },

    async fetchPreviousData() {
      const orderRef = collection(db, "orders");
      let firstDoc = this.tableData[0];
      firstDoc = await getDoc(doc(orderRef, firstDoc.id));
      this.tableData = [];
      try {
        let orderQuery;
        orderQuery = query(
          orderRef,
          orderBy("createdAt", "asc"),
          startAfter(firstDoc)
        );

        const unsub = await onSnapshot(orderQuery, (querySnapshot) => {
          this.orders = querySnapshot.docs
            .map((doc) => Object.assign({ id: doc.id }, doc.data()))
            .reverse();
          this.tableData = this.orders;
        });
      } catch (error) {
        console.debug("Error fetching data:", error);
      }
    },

    //Filter Methods
    async handleFilter() {
      let ref = collection(db, "orders");

      if (
        !this.filterBy.status &&
        !this.filterBy.startDate &&
        !this.filterBy.endDate
      ) {
        this.resetTable();
        return false;
      }

      try {
        if (this.filterBy.startDate && this.filterBy.endDate) {
          timeFilter = true;
          let startDateObj =
            typeof this.filterBy.startDate == "object"
              ? DateTime.fromJSDate(this.filterBy.startDate)
              : DateTime.fromISO(this.filterBy.startDate);

          let endDateObj =
            typeof this.filterBy.endDate == "object"
              ? DateTime.fromJSDate(this.filterBy.endDate)
              : DateTime.fromISO(this.filterBy.endDate);

          let startDate = startDateObj
            .setZone("America/New_York")
            .startOf("day")
            .toMillis();

          let endDate = endDateObj
            .setZone("America/New_York")
            .endOf("day")
            .toMillis();

          ref = ref
            .orderBy("createdAt", "desc")
            .startAt(endDate)
            .endAt(startDate);
        }

        for (const key in this.filterBy) {
          if (Object.hasOwnProperty.call(this.filterBy, key)) {
            if (key != "startDate" && key != "endDate") {
              const element = this.filterBy[key];
              if (element) {
                if (key == "status") {
                  ref = query(ref, where("status", "==", element));
                }
              }
            }
          }
        }

        await onSnapshot(ref, (querySnapshot) => {
          if (!querySnapshot.docs.length) {
            return this.$swal("Empty Search Records");
          }
          this.searchedData = querySnapshot.docs.map((d) =>
            Object.assign({ id: d.id }, d.data())
          );
        });
      } catch (error) {
        console.debug(error);

        return this.$swal({
          icon: "error",
          title: "Internal Issue",
          text: "Oops there seems to be an issue please try again or contact support at info@mylunacollection.com",
          type: "error",
        });
      }
    },
    clearFilters() {
      for (const key in this.filterBy) {
        if (key === "startDate" || key === "endDate") {
          this.filterBy[key] = null;
        } else {
          this.filterBy[key] = "";
        }
      }
      this.searchVal = null;
      this.resetTable();
    },
    searchForOrder(e) {
      if (!e.target.value) {
        this.tableData = this.order;
      }
      let input = e.target.value.toLowerCase();
      const validateOrder = (order) => {
        if (
          order.orderNumber.toLowerCase().includes(input) ||
          order.purchaseOrderNumber.toLowerCase().includes(input)
        ) {
          return true;
        } else return false;
      };
      this.tableData = this.orders.filter((order) => validateOrder(order));
    },
  },
};
</script>
<style scoped>
.table-row {
  cursor: pointer;
}

.search-bar {
  position: relative;
  width: 535px !important;
  top: 10px;
  left: 10px;
  display: flex;
}

.color-key-container {
  width: 258px;
  position: relative;
}

.color-key-dropdown {
  position: absolute;
  width: 100%;
  top: 30px;
  left: 24px;
  z-index: 999;
}

.color-status {
  width: 100%;
  text-align: center;
}

.color-status:hover {
  transform: scale(1.2);
}
</style>
