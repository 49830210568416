<template>
  <div class="order-confirmation" ref="contentToExport">
    <div class="logo-container">
      <img
        class="logo"
        src="@/assets/img/Luna-Collection.png"
        alt=""
        srcset=""
      />
    </div>
    <header>
      <h1>Order Confirmation</h1>
    </header>
    <section>
      <p>
        Dear {{ `${order.consumer.firstName} ${order.consumer.lastName}` }},
      </p>
      <p>
        Thank you for shopping at Luna Collection! We are thrilled to confirm
        that your order has been successfully processed and is on its way to
        you. Your support means the world to us, and we are grateful for the
        opportunity to be a part of your jewelry collection.
      </p>

      <div class="order-ids">
        <p>Purchase Order #: {{ order.purchaseOrderNumber }}</p>
        <p>Customer #: {{ order.orderNumber }}</p>
        <p>Date: {{ format_date(order.createdAt) }}</p>
      </div>
      <table>
        <thead>
          <tr>
            <th>Thumbnail</th>
            <th>Order #</th>
            <th>Item(s) Ordered</th>
            <th>SKU</th>
            <th v-if="order.jewelryType != 'Earring'">Size</th>
            <th>Metal</th>
            <th>Shape</th>
            <th>Quality</th>
            <th v-if="order.engravings || order.birthStones.length">
              Customization
            </th>
            <!-- <th>Price</th> -->
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div class="images">
                <img
                  class="image"
                  :src="order.productImageDataUrl"
                  alt=""
                  srcset=""
                />
              </div>
            </td>
            <td>{{ order.orderNumber }}</td>
            <td>
              {{
                `${order.origin} ${
                  order.jewelryType == "Ring" ? `Size ${order.size}` : ""
                }  ${order.caratWeight}ct t.w ${order.shape} ${
                  order.metal.name
                } ${order.style} ${order.jewelryType}`
              }}
            </td>
            <td>{{ order.sku }}</td>
            <td v-if="order.jewelryType != 'Earring'">{{ order.size }}</td>
            <td>{{ order.metal.name }}</td>
            <td>{{ order.shape }}</td>
            <td>{{ order.quality }}</td>
            <td v-if="order.engravings || order.birthStones.length">
              <table>
                <thead>
                  <tr>
                    <th>Engravings</th>
                    <th>Birthstones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td v-if="order.engravings">
                      <div class="engravings">
                        <!-- <p>Font: {{ order.engravings.font }}</p> -->
                        <p
                          :style="{
                            'font-family': order.engravings.font,
                            'font-style': 'italic',
                            'text-align': 'center',
                          }"
                        >
                          {{ order.engravings.text }}
                        </p>
                      </div>
                    </td>
                    <td v-if="order.birthStones.length">
                      <div class="birthstones">
                        <p v-for="stone in order.birthStones" :key="stone.name">
                          {{ stone.name }}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <!-- <td >
              {{ format_price(order.orginalPrice) }}
            </td> -->
            <td>{{ order.quantity }}</td>
          </tr>
        </tbody>
        <!-- <tfoot>
          <tr>
            <td
              style="text-align: left !important"
              :colspan="order.engravings || order.birthStones.length ? 5 : 4"
            >
              Total
            </td>
            <td>{{ format_price(order.totalCost) }}</td>
          </tr>
        </tfoot> -->
      </table>
      <div>
        If you have any questions or concerns regarding your order, please do
        not hesitate to contact our customer service team at
        <a href="mailto:info@mylunacollection.com">info@mylunacollection.com</a
        >.
      </div>
      <div>
        We hope you enjoy your new jewelry pieces from Luna Collection. Make
        sure to show off your fabulous style and tag us on social media
        @LunaCollection.
      </div>
      <div>
        Thank you for choosing Luna Collection for your jewelry needs. We
        appreciate your business!
      </div>
    </section>
    <footer>
      <p>Luna Collection</p>
      <p>
        Email:
        <a href="mailto:info@mylunacollection.com">info@mylunacollection.com</a>
      </p>
    </footer>
    <div class="disclaimer">
      <strong>NOTE:</strong> This is not an invoice. The prices and products
      contained in this order confirmation are for reference only and are
      subject to change.
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderConfirmation",
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped>
.logo-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  background-image: url("../../../assets/img/LC-background.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.logo {
  width: 30%;
}
.order-confirmation {
  font-family: Arial, sans-serif;
  margin: 20px;
  width: 100%;
}

header {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

h1 {
  font-size: 24px;
}

section {
  margin-bottom: 20px;
}

.order-ids {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  text-align: center;
}

td {
  font-size: 14px;
}

tfoot td {
  font-weight: bold;
}

footer {
  text-align: left;
}

footer p {
  margin: 5px 0;
  font-size: 14px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.disclaimer {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 14px;
}

.image {
  width: 100px;
  height: 100px;
}
.images {
  display: flex;
  width: 145px;
  justify-content: center;
}
</style>
